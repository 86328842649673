// noinspection AllyJsxHardcodedStringInspection

import React, { useEffect } from "react";
import { Link, useI18next, Helmet } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";

const NotFoundPage = () => {
    const { navigate } = useI18next();

    useEffect(() => {
        setTimeout(() => {
            // noinspection JSIgnoredPromiseFromCall
            navigate("/");
        }, 5000);
    }, []);

    return (
        <>
            <Helmet>
                <title>Not found</title>
            </Helmet>
            <main className="font-bold text-center h-screen grid gap-6 place-items-center place-content-center">
                <em className="block text-purple-200 text-9xl lg:text-[267px] italic">
                    404
                </em>
                <h1 className="text-purple-200 text-2xl lg:text-4xl">
                    Page not found
                </h1>
                <StaticImage
                    src="../images/not-found.png"
                    alt="..."
                    className="w-[185px] lg:w-[330px] bg-white"
                    placeholder="none"
                />
                <Link
                    to="/"
                    className="uppercase inline-block text-white text-xl lg:text-4xl bg-[#8FC5E4] rounded-lg p-3 px-10"
                >
                    Homepage
                </Link>
            </main>
        </>
    );
};

export default NotFoundPage;

export { Head } from "components/GCSRewriteURL";
